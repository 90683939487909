import { icon as solanaIcon } from './icon/solana';
import { icon as ethereumIcon } from './icon/ethereum';
import { icon as bscIcon } from './icon/bsc';
import { icon as polygonIcon } from './icon/polygon';
import { icon as avalancheIcon } from './icon/avalanche';
import { icon as moonbeamIcon } from './icon/moonbeam';
import { icon as moonriverIcon } from './icon/moonriver';
import { icon as hecoIcon } from './icon/heco';
import { icon as fantomIcon } from './icon/fantom';
import { icon as arbitrumIcon } from './icon/arbitrum';
import { icon as harmonyIcon } from './icon/harmony';
import { icon as auroraIcon } from './icon/aurora';
import { icon as optimismIcon } from './icon/optimism';
import { icon as kccIcon } from './icon/kcc';
import { icon as platONIcon } from './icon/platON';
import { icon as tronIcon } from './icon/tron';
import { icon as okcIcon } from './icon/okc';
import { icon as thunderCoreIcon } from './icon/thunderCore';
import { icon as cronosIcon } from './icon/cronos';
import { icon as oasisEmeraldIcon } from './icon/oasisEmerald';

const chainMap = {
    Solana: {
        icon: solanaIcon,
        nativeCurrency: { name: 'SOL', symbol: 'SOL', decimals: 9 },
        chainType: 'solana',
        supportEIP1559: false,
    },
    Ethereum: {
        icon: ethereumIcon,
        nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
    BSC: {
        icon: bscIcon,
        nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    BSC97: {
        icon: bscIcon,
        nativeCurrency: {
            name: 'Binance Chain Native Token',
            symbol: 'tBNB',
            decimals: 18,
        },
        chainType: 'evm',
        supportEIP1559: false,
    },
    Polygon: {
        icon: polygonIcon,
        nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
    Avalanche: {
        icon: avalancheIcon,
        nativeCurrency: { name: 'AVAX', symbol: 'AVAX', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
    Moonbeam1287: {
        icon: moonbeamIcon,
        nativeCurrency: {
            name: 'Dev',
            symbol: 'DEV',
            decimals: 18,
        },
        chainType: 'evm',
        supportEIP1559: true,
    },
    Moonbeam: {
        icon: moonbeamIcon,
        nativeCurrency: { name: 'GLMR', symbol: 'GLMR', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
    Moonriver: {
        icon: moonriverIcon,
        nativeCurrency: { name: 'MOVR', symbol: 'MOVR', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
    Heco: {
        icon: hecoIcon,
        nativeCurrency: { name: 'HT', symbol: 'HT', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
    Fantom: {
        icon: fantomIcon,
        nativeCurrency: { name: 'FTM', symbol: 'FTM', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },

    Arbitrum: {
        icon: arbitrumIcon,
        nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
    ArbitrumRinkeby: {
        icon: arbitrumIcon,
        nativeCurrency: {
            name: 'Arbitrum Rinkeby Ether',
            symbol: 'ETH',
            decimals: 18,
        },
        chainType: 'evm',
        supportEIP1559: true,
    },

    ArbitrumGoerli: {
        icon: arbitrumIcon,
        nativeCurrency: {
            name: 'Arbitrum Görli Ether',
            symbol: 'AGOR',
            decimals: 18,
        },
        chainType: 'evm',
        supportEIP1559: true,
    },
    Harmony: {
        icon: harmonyIcon,
        nativeCurrency: { name: 'ONE', symbol: 'ONE', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    Aurora: {
        icon: auroraIcon,
        nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    Optimism: {
        icon: optimismIcon,
        nativeCurrency: {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18,
        },
        chainType: 'evm',
        supportEIP1559: false,
    },
    KCC: {
        icon: kccIcon,
        nativeCurrency: { name: 'KCS', symbol: 'KCS', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    PlatON: {
        icon: platONIcon,
        nativeCurrency: { name: 'LAT', symbol: 'LAT', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    Tron: {
        icon: tronIcon,
        nativeCurrency: { name: 'TRX', symbol: 'TRX', decimals: 6 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    Okc: {
        icon: okcIcon,
        nativeCurrency: { name: 'OKT', symbol: 'OKT', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    ThunderCore: {
        icon: thunderCoreIcon,
        nativeCurrency: { name: 'ThunderCore Token', symbol: 'TT', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    Cronos: {
        icon: cronosIcon,
        nativeCurrency: { name: 'Cronos', symbol: 'CRO', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
    OasisEmerald: {
        icon: oasisEmeraldIcon,
        nativeCurrency: { name: 'OasisEmerald', symbol: 'ROSE', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
};

export const Tron = {
    id: 728126428,
    name: 'Tron',
    network: 'Mainnet',
    fullname: 'Tron Mainnet',
    rpcUrl: 'https://api.trongrid.io',
    blockExplorerUrls: ['https://tronscan.io'],
    ...chainMap.Tron,
};

export const TronShasta = {
    id: 2494104990,
    name: 'Tron',
    network: 'Shasta',
    fullname: 'Tron Shasta Testnet',
    rpcUrl: 'https://api.shasta.trongrid.io',
    blockExplorerUrls: ['https://shasta.tronscan.org'],
    ...chainMap.Tron,
};

export const TronNile = {
    id: 3448148188,
    name: 'Tron',
    network: 'Nile',
    fullname: 'Tron Nile Testnet',
    rpcUrl: 'https://nile.trongrid.io',
    blockExplorerUrls: ['https://nile.tronscan.org'],
    ...chainMap.Tron,
};

export const PlatON = {
    id: 210425,
    name: 'PlatON',
    network: 'Mainnet',
    fullname: 'PlatON Mainnet',
    rpcUrl: 'https://openapi2.platon.network/rpc',
    blockExplorerUrls: ['https://scan.platon.network'],
    ...chainMap.PlatON,
};

export const PlatONTestnet = {
    id: 2203181,
    name: 'PlatON',
    network: 'Testnet',
    fullname: 'PlatON Dev Testnet',
    rpcUrl: 'https://devnetopenapi2.platon.network/rpc',
    blockExplorerUrls: ['https://devnetscan.platon.network'],
    ...chainMap.PlatON,
};

export const KCC = {
    id: 321,
    name: 'KCC',
    network: 'Mainnet',
    fullname: 'KCC Mainnet',
    rpcUrl: 'https://rpc-mainnet.kcc.network',
    blockExplorerUrls: ['https://explorer.kcc.io/en'],
    ...chainMap.KCC,
};

export const KCCTestnet = {
    id: 322,
    name: 'KCC',
    network: 'Testnet',
    fullname: 'KCC Testnet',
    rpcUrl: 'https://rpc-testnet.kcc.network',
    blockExplorerUrls: ['https://scan-testnet.kcc.network'],
    ...chainMap.KCC,
};

export const Optimism = {
    id: 10,
    name: 'Optimism',
    network: 'Mainnet',
    fullname: 'Optimism',
    rpcUrl: 'https://mainnet.optimism.io/',
    blockExplorerUrls: ['https://optimistic.etherscan.io'],
    ...chainMap.Optimism,
};

export const OptimismTestnet = {
    id: 420,
    name: 'Optimism',
    network: 'Testnet',
    fullname: 'Optimism Goerli Testnet',
    rpcUrl: 'https://goerli.optimism.io/',
    blockExplorerUrls: ['https://goerli-optimism.etherscan.io'],
    ...chainMap.Optimism,
};

export const Aurora = {
    id: 1313161554,
    name: 'Aurora',
    network: 'Mainnet',
    fullname: 'Aurora Mainnet',
    rpcUrl: 'https://mainnet.aurora.dev',
    blockExplorerUrls: ['https://aurorascan.dev'],
    ...chainMap.Aurora,
};

export const AuroraTestnet = {
    id: 1313161555,
    name: 'Aurora',
    network: 'Testnet',
    fullname: 'Aurora Testnet',
    rpcUrl: 'https://testnet.aurora.dev',
    blockExplorerUrls: ['https://testnet.aurorascan.dev'],
    ...chainMap.Aurora,
};

export const Harmony = {
    id: 1666600000,
    name: 'Harmony',
    network: 'Mainnet',
    fullname: 'Harmony Mainnet Shard 0',
    rpcUrl: 'https://api.harmony.one',
    blockExplorerUrls: ['https://explorer.harmony.one'],
    ...chainMap.Harmony,
};

export const HarmonyTestnet = {
    id: 1666700000,
    name: 'Harmony',
    network: 'Testnet',
    fullname: 'Harmony Testnet Shard 0',
    rpcUrl: 'https://api.s0.b.hmny.io',
    blockExplorerUrls: ['https://explorer.pops.one'],
    ...chainMap.Harmony,
};

export const Arbitrum = {
    id: 42161,
    name: 'Arbitrum',
    network: 'Mainnet',
    fullname: 'Arbitrum One',
    rpcUrl: 'https://arb1.arbitrum.io/rpc',
    blockExplorerUrls: ['https://explorer.arbitrum.io'],
    ...chainMap.Arbitrum,
};

export const ArbitrumTestnet = {
    id: 421611,
    name: 'Arbitrum',
    network: 'Testnet',
    fullname: 'Arbitrum Rinkeby',
    rpcUrl: 'https://rinkeby.arbitrum.io/rpc',
    blockExplorerUrls: ['https://rinkeby-explorer.arbitrum.io'],
    ...chainMap.ArbitrumRinkeby,
};

export const ArbitrumGoerli = {
    id: 421613,
    name: 'Arbitrum',
    network: 'Goerli',
    fullname: 'Arbitrum Görli',
    rpcUrl: 'https://goerli-rollup.arbitrum.io/rpc',
    blockExplorerUrls: ['https://goerli-rollup-explorer.arbitrum.io'],
    ...chainMap.ArbitrumGoerli,
};

export const Fantom = {
    id: 250,
    name: 'Fantom',
    network: 'Mainnet',
    fullname: 'Fantom Opera',
    rpcUrl: 'https://rpc.ftm.tools',
    blockExplorerUrls: ['https://ftmscan.com'],
    ...chainMap.Fantom,
};

export const FantomTestnet = {
    id: 4002,
    name: 'Fantom',
    network: 'Testnet',
    fullname: 'Fantom Testnet',
    rpcUrl: 'https://rpc.testnet.fantom.network',
    blockExplorerUrls: ['https://testnet.ftmscan.com'],
    ...chainMap.Fantom,
};

export const Heco = {
    id: 128,
    name: 'Heco',
    network: 'Mainnet',
    fullname: 'Huobi ECO Chain Mainnet',
    rpcUrl: 'https://http-mainnet.hecochain.com',
    blockExplorerUrls: ['https://hecoinfo.com'],
    ...chainMap.Heco,
};

export const HecoTestnet = {
    id: 256,
    name: 'Heco',
    network: 'Testnet',
    fullname: 'Huobi ECO Chain Testnet',
    rpcUrl: 'https://http-testnet.hecochain.com',
    blockExplorerUrls: ['https://testnet.hecoinfo.com'],
    ...chainMap.Heco,
};

export const Moonriver = {
    id: 1285,
    name: 'Moonriver',
    network: 'Mainnet',
    fullname: 'Moonriver',
    rpcUrl: 'https://rpc.api.moonriver.moonbeam.network',
    blockExplorerUrls: ['https://moonriver.moonscan.io'],

    ...chainMap.Moonriver,
};

export const MoonriverTestnet = {
    id: 1287,
    name: 'Moonriver',
    network: 'Testnet',
    fullname: 'Moonbase Alpha',
    rpcUrl: 'https://rpc.api.moonbase.moonbeam.network',
    blockExplorerUrls: ['https://moonbase.moonscan.io'],
    ...chainMap.Moonbeam1287,
};

export const Moonbeam = {
    id: 1284,
    name: 'Moonbeam',
    network: 'Mainnet',
    fullname: 'Moonbeam',
    rpcUrl: 'https://rpc.api.moonbeam.network',
    blockExplorerUrls: ['https://moonbeam.moonscan.io'],
    ...chainMap.Moonbeam,
};

export const MoonbeamTestnet = {
    id: 1287,
    name: 'Moonbeam',
    network: 'Testnet',
    fullname: 'Moonbase Alpha',
    rpcUrl: 'https://rpc.api.moonbase.moonbeam.network',
    blockExplorerUrls: ['https://moonbase.moonscan.io'],
    ...chainMap.Moonbeam1287,
};

export const Avalanche = {
    id: 43114,
    name: 'Avalanche',
    network: 'Mainnet',
    fullname: 'Avalanche C-Chain',
    rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
    blockExplorerUrls: ['https://snowtrace.io'],
    ...chainMap.Avalanche,
};

export const AvalancheTestnet = {
    id: 43113,
    name: 'Avalanche',
    network: 'Testnet',
    fullname: 'Avalanche Fuji Testnet',
    rpcUrl: 'https://api.avax-test.network/ext/bc/C/rpc',
    blockExplorerUrls: ['https://testnet.snowtrace.io'],

    ...chainMap.Avalanche,
};

export const Polygon = {
    id: 137,
    name: 'Polygon',
    network: 'Mainnet',
    fullname: 'Polygon Mainnet',
    rpcUrl: 'https://polygon-rpc.com',
    blockExplorerUrls: ['https://polygonscan.com'],
    ...chainMap.Polygon,
};

export const PolygonMumbai = {
    id: 80001,
    name: 'Polygon',
    network: 'Mumbai',
    fullname: 'Mumbai',
    rpcUrl: 'https://matic-mumbai.chainstacklabs.com',
    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
    ...chainMap.Polygon,
};

export const BSC = {
    id: 56,
    name: 'BSC',
    network: 'Mainnet',
    fullname: 'Binance Smart Chain Mainnet',
    rpcUrl: 'https://bsc-dataseed1.binance.org',
    ...chainMap.BSC,
    blockExplorerUrls: ['https://bscscan.com'],
};

export const BSCTestnet = {
    id: 97,
    name: 'BSC',
    network: 'Testnet',
    fullname: 'Binance Smart Chain Testnet',
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    ...chainMap.BSC,
    blockExplorerUrls: ['https://testnet.bscscan.com'],
};

export const Ethereum = {
    id: 1,
    name: 'Ethereum',
    network: 'Mainnet',
    fullname: 'Ethereum Mainnet',
    rpcUrl: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    blockExplorerUrls: ['https://etherscan.io'],
    ...chainMap.Ethereum,
};

export const EthereumGoerli = {
    id: 5,
    name: 'Ethereum',
    network: 'Goerli',
    fullname: 'Görli',
    rpcUrl: 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    ...chainMap.Ethereum,
    blockExplorerUrls: ['https://goerli.etherscan.io'],
};

export const Solana = {
    id: 101,
    name: 'Solana',
    network: 'Mainnet',
    rpcUrl: 'https://api.mainnet-beta.solana.com',
    blockExplorerUrls: [],
    fullname: 'Solana',
    ...chainMap.Solana,
};

export const SolanaTestnet = {
    id: 102,
    name: 'Solana',
    network: 'Testnet',
    fullname: 'Solana Testnet',
    rpcUrl: 'https://api.testnet.solana.com',
    blockExplorerUrls: [],
    ...chainMap.Solana,
};

export const SolanaDevnet = {
    id: 103,
    name: 'Solana',
    network: 'Devnet',
    fullname: 'Solana Devnet',
    rpcUrl: 'https://api.devnet.solana.com',
    ...chainMap.Solana,
    blockExplorerUrls: [],
};

export const OKC = {
    id: 66,
    name: 'OKC',
    network: 'Mainnet',
    fullname: 'OKC Mainnet',
    rpcUrl: 'https://exchainrpc.okex.org',
    ...chainMap.Okc,
    blockExplorerUrls: ['https://www.oklink.com/okc'],
};

export const OKCTestnet = {
    id: 65,
    name: 'OKC',
    network: 'Testnet',
    fullname: 'OKC Testnet',
    rpcUrl: 'https://exchaintestrpc.okex.org',
    ...chainMap.Okc,
    blockExplorerUrls: ['https://www.oklink.com/okc-test'],
};

export const ThunderCore = {
    id: 108,
    name: 'ThunderCore',
    network: 'Mainnet',
    fullname: 'ThunderCore Mainnet',
    rpcUrl: 'https://mainnet-rpc.thundercore.com',
    ...chainMap.ThunderCore,
    blockExplorerUrls: ['https://viewblock.io/thundercore'],
};

export const ThunderCoreTestnet = {
    id: 18,
    name: 'ThunderCore',
    network: 'Testnet',
    fullname: 'ThunderCore Testnet',
    rpcUrl: 'https://testnet-rpc.thundercore.com',
    ...chainMap.ThunderCore,
    blockExplorerUrls: ['https://explorer-testnet.thundercore.com', 'https://faucet-testnet.thundercore.com'],
};

export const Cronos = {
    id: 25,
    name: 'Cronos',
    network: 'Mainnet',
    fullname: 'Cronos Mainnet',
    rpcUrl: 'https://evm.cronos.org',
    ...chainMap.Cronos,
    blockExplorerUrls: ['https://cronoscan.com'],
};

export const CronosTestnet = {
    id: 338,
    name: 'Cronos',
    network: 'Testnet',
    fullname: 'Cronos Testnet',
    rpcUrl: 'https://evm-t3.cronos.org',
    ...chainMap.Cronos,
    blockExplorerUrls: ['https://testnet.cronoscan.com'],
};

export const OasisEmerald = {
    id: 42262,
    name: 'OasisEmerald',
    network: 'Mainnet',
    fullname: 'OasisEmerald Mainnet',
    rpcUrl: 'https://emerald.oasis.dev',
    ...chainMap.OasisEmerald,
    blockExplorerUrls: ['https://explorer.emerald.oasis.dev'],
};

export const OasisEmeraldTestnet = {
    id: 42261,
    name: 'OasisEmerald',
    network: 'Testnet',
    fullname: 'OasisEmerald Testnet',
    rpcUrl: 'https://testnet.emerald.oasis.dev',
    ...chainMap.OasisEmerald,
    blockExplorerUrls: ['https://testnet.explorer.emerald.oasis.dev'],
};

export interface ChainInfo {
    id: ChainId;
    name: ChainName;
    network: string;
    icon: string;
    rpcUrl: string;
    nativeCurrency: {
        name: string;
        symbol: string;
        decimals: number;
    };
    chainType: string;
    supportEIP1559: boolean;
    blockExplorerUrls: string[];
    fullname: string;
}

export const ParticleChains: {
    [key: string]: ChainInfo;
} = {
    OKC,
    OKCTestnet,
    PlatON,
    PlatONTestnet,
    Harmony,
    HarmonyTestnet,
    Heco,
    HecoTestnet,
    KCC,
    KCCTestnet,
    Optimism,
    OptimismTestnet,
    Aurora,
    AuroraTestnet,
    Arbitrum,
    ArbitrumGoerli,
    Fantom,
    FantomTestnet,
    Moonbeam,
    MoonbeamTestnet,
    Moonriver,
    MoonriverTestnet,
    Avalanche,
    AvalancheTestnet,
    Polygon,
    PolygonMumbai,
    BSC,
    BSCTestnet,
    Ethereum,
    EthereumGoerli,
    Tron,
    TronShasta,
    TronNile,
    Solana,
    SolanaDevnet,
    SolanaTestnet,
    ThunderCore,
    ThunderCoreTestnet,
    Cronos,
    CronosTestnet,
    OasisEmerald,
    OasisEmeraldTestnet,
};

export type ChainType = 'solana' | 'evm';
export type ChainName = string;
export type ChainId = number;

export interface Chain {
    id: ChainId;
    name: ChainName;
}
